<template>
  <div class="tolead" :style="{height: $store.state.menuFlag?'100%':'calc(100% - 70px)'}">
    <div style="margin-bottom: 1.0417vw">
      <!--      <el-select v-model="value" placeholder="请选择" @change="ProjectChange">-->
      <!--        <el-option v-for="(item, index) in Project" :key="index" :label="item.Name" :value="item.Id">-->
      <!--        </el-option>-->
      <!--      </el-select>-->
      <Cascader :value.sync="value" :options="Project" @MyChange="ProjectChange" :multiple="false"></Cascader>
    </div>
    <div class="charts-container">
      <div class="row1">
        <div class="chart1">
          <div class="title">
            <span></span>
            <span>系统患者数</span>
          </div>
          <div class="content">
            <div>
              <div>
                <p>当前总数</p>
                <div>
                  <p>{{ NumberOfPatients.PatientTotal }}</p>
                  <img src="../../assets/image/taskmanagement/projecttype1.png" alt=""
                       style="width: 1.4063vw;height: 1.4063vw">
                </div>
              </div>
              <div>
                <p>本季总数</p>
                <div>
                  <p>{{ NumberOfPatients.PatientQTotal }}</p>
                  <img src="../../assets/image/taskmanagement/projecttype1.png" alt=""
                       style="width: 1.4063vw;height: 1.4063vw">
                </div>
              </div>
              <div>
                <p>本年总数</p>
                <div>
                  <p>{{ NumberOfPatients.PatientYTotal }}</p>
                  <img src="../../assets/image/taskmanagement/projecttype1.png" alt=""
                       style="width: 1.4063vw;height: 1.4063vw">
                </div>
              </div>
            </div>
            <div>
              <div>
                <p>年拨打数</p>
                <div>
                  <p>{{ NumberOfPatients.PatientYCallTotal }}</p>
                  <img src="../../assets/image/taskmanagement/projecttype2.png" alt=""
                       style="width: 1.4063vw;height: 1.4063vw">
                </div>
              </div>
              <div>
                <p>季拨打数</p>
                <div>
                  <p>{{ NumberOfPatients.PatientQCallTotal }}</p>
                  <img src="../../assets/image/taskmanagement/projecttype2.png" alt=""
                       style="width: 1.4063vw;height: 1.4063vw">
                </div>
              </div>
              <div>
                <p>月拨打数</p>
                <div>
                  <p>{{ NumberOfPatients.PatientMCallTotal }}</p>
                  <img src="../../assets/image/taskmanagement/projecttype2.png" alt=""
                       style="width: 1.4063vw;height: 1.4063vw">
                </div>
              </div>
              <div>
                <p>周拨打数</p>
                <div>
                  <p>{{ NumberOfPatients.PatientWCallTotal }}</p>
                  <img src="../../assets/image/taskmanagement/projecttype2.png" alt=""
                       style="width: 1.4063vw;height: 1.4063vw">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart2">
          <div style="display: flex;margin-left: 0.5208vw;margin-top: 0.5208vw">
            <div v-for="(item, index) in followNumber" :key="index" class="follow-number">
              {{ item }}
            </div>
          </div>
          <div class="bottomdiv">
            <span>随访总人次数</span>
          </div>
        </div>
        <div class="chart3">
          <div class="title">
            <span></span>
            <span>随访情况</span>
          </div>
          <div id="staisticsSF" style="width: 100%;height: calc(100% - 1.1458vw);min-height: 160px;"></div>
        </div>
      </div>
      <div class="row2">
        <div class="chart4">
          <div class="title">
            <span></span><span>年龄分类人数图</span>
          </div>
          <div id="staisticsA" style="width: 100%;height: calc(100% - 1.1458vw)"></div>
        </div>
        <div class="chart5">
          <div class="title">
            <span></span><span>全部AI有效随访人群中不同年龄</span>
          </div>
          <div id="staisticsC" style="width: 100%;height: calc(100% - 1.1458vw);min-height: 210px;"></div>
        </div>
        <div class="chart6">
          <div class="title">
            <span></span><span>患者分布区域</span>
          </div>
          <div id="StaisticsMap" style="width: 100%;height: calc(100% - 1.1458vw)"></div>
        </div>
        <div class="chart7">
          <div class="title">
            <span></span><span>数量类型占比</span>
          </div>
          <div id="staisticsB" style="width: 100%;height: calc(100% - 1.1458vw)"></div>
        </div>
        <div class="chart8">
          <div class="title">
            <span></span><span>AI电话接通数</span>
          </div>
          <div id="staisticsD" style="width: 100%;height: calc(100% - 1.1458vw);min-height: 170px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ArrayZero} from "@/utils/filter";
import * as echarts from "echarts";
import china from 'echarts/map/json/china.json'

echarts.registerMap('china', china)
import Statistics from "../../api/Statistics"
import derive from "../../api/derive"
import Cascader from "@/components/Cascader.vue";

export default {
  name: "statistics",
  components: {Cascader},
  data() {
    return {
      // PatientCallTotal:'',//随访总人次数
      Project: [{Name: "全部", Id: "ALL", ParentId: null}],
      value: ["ALL"],
      followNumber: [],
      total: 0,
      NumberOfPatients: {},//系统患者数.
      Part1: [],//年龄分类人数图
      Part2: [],//全部AI有效随访人群中不同年龄
      Part3: [],//最近一月内AI有效随访人群中不同年龄
      Part4: [],//数量类型占比
      Part5: [],//AI电话接通数
      Part6: [],//患者分布区域
      NoticeMsg: [],//追踪通知
      TraceMsg: [],//消息通知
      list: [{name: '本季数'},
        {name: '本月数'},
        {name: '本周数'},
        {name: '最近3天'}],
      arr: [{name: '男性'}, {name: '女性'}, {name: '女性'}, {name: '最近一周男性接通'}, {name: '最近一周女性接通'}],
      arrlist: [],
      devicePixelRatio: ''
    }
  },
  created() {
    // this.ProjectList()
  },
  mounted() {
    this.devicePixelRatio = document.documentElement.clientWidth < 1100;
    this.ProjectList()
  },
  methods: {
    ProjectChange(e) {
      this.StatisticsPart(e)
    },
    //项目
    async ProjectList() {
      derive.ProjectListData().then(res => {
        if (res.data.Status == 1) {
          this.Project.push(...res.data.Data)
          this.StatisticsPart(this.value)
        } else {
          alert(res.data.Message)
        }
      })
    },
    StatisticsPart(value) {
      let parameter = {
        ProjectId: value.slice(-1)
      }
      console.log(parameter);
      Statistics.StatisticsPart1Data(parameter).then(res => {
        if (res.data.Status == 1) {
          this.NumberOfPatients = res.data.Data
          this.arrlist = [
            {name: '随访总数', value: this.NumberOfPatients.PatientTotal},
            {name: '本季数', value: this.NumberOfPatients.PatientQCallTotal},
            {name: '本月数', value: this.NumberOfPatients.PatientMCallTotal},
            {name: '本周', value: this.NumberOfPatients.PatientWCallTotal},
            {name: '最近', value: this.NumberOfPatients.PatientLCallTotal},
          ]
          this.Part5 = [
            {name: '本季数', value: this.NumberOfPatients.PatientQCallTotal},
            {name: '本月数', value: this.NumberOfPatients.PatientMCallTotal},
            {name: '本周数', value: this.NumberOfPatients.PatientWCallTotal},
            {name: '最近3天', value: this.NumberOfPatients.PatientLCallTotal},
          ]
          this.total = this.NumberOfPatients.PatientYCallTotal
          this.$nextTick(() => {
            this.statisticListD()
          })
          this.followNumber = ArrayZero(this.NumberOfPatients.PatientCallTotal, 7)
        } else {
          alert(res.data.Message)
        }
        console.log(res, "统计1");
      })
      Statistics.StatisticsPart2Data(parameter).then(res => {
        if (res.data.Status = 1) {
          this.Part1 = res.data.Data.Part1
          this.Part2 = res.data.Data.Part2
          this.Part3 = res.data.Data.Part3
          // this.Part4=res.data.Data.Part4.map((item,i)=>{
          //        if(i<=4){
          //         return {
          //            value:item,
          //            name:this.arr[i].name
          //         }
          //       }
          // })
          // this.Part5=res.data.Data.Part5.map((item,i)=>{
          //       if(i<=3){
          //         return {
          //            value:item,
          //            name:this.list[i].name
          //         }
          //       }
          // })
          this.Part6 = res.data.Data.Part6
          this.$nextTick(() => {
            setTimeout(() => {
              this.statisticListSF()
            }, 10)
            // this.statisticListSF()
            this.statisticListA()
            this.statisticListB()
            this.statisticListC()
            // this.statisticListC1()
            // this.statisticListD()
            this.StaisticsMap()
          })
        } else {
          alert(res.data.Message)
        }
        console.log(res, "统计2");
      })
      Statistics.StatisticsPart3Data(parameter).then(res => {
        if (res.data.Status == 1) {
          // this.NoticeMsg=res.data.Data.NoticeMsg
          // this.TraceMsg=res.data.Data.TraceMsg
          this.Part4 = res.data.Data.map((item) => {
            return {
              value: item.Count,
              name: item.StatusName
            }
          })
          this.$nextTick(() => {
            this.statisticListB()
          })
        } else {
          alert(res.data.Message)
        }
        console.log(res, "统计3");
      })
    },
    load() {
      console.log(11111)
    },
    load2() {
      console.log(11111)
    },
    statistResize(chartDom, myChart) { // 表格的自适应
      return
      var width = document.documentElement.clientWidth;
      if (width <= 1600) {
        chartDom.style.width = '18.75vw'
      } else {
        chartDom.style.width = '24.4792vw'
      }
      // window.onresize = myChart.resize;
      myChart.resize()
    },
    statisticListA() {
      var chartDom = document.getElementById('staisticsA');
      var myChart = echarts.init(chartDom);
      this.statistResize(chartDom, myChart);
      var option;
      option = {
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {trigger: 'axis', axisPointer: {type: 'shadow',},},
        grid: {top: '15%', right: '0%', left: '8%', bottom: '12%',},
        xAxis: [
          {
            type: 'category',
            data: ['0-10', '11-20', '21-30', '31-40', '41-50', '51-60', '61-70', '80以上'],
            axisLine: {lineStyle: {color: '#EEEEEE',},},
            axisLabel: {
              margin: 10,
              color: '#999999',
              rotate: 20,//角度顺时针计算的
              textStyle: {fontSize: 14,},
            },
            axisTick: {show: false,},
          },
        ],
        yAxis: [
          {
            axisLabel: {formatter: '{value}', color: '#999999',},
            axisTick: {show: false,},
            axisLine: {show: false, lineStyle: {color: 'rgba(0,186,255,.6)',},},
            splitLine: {lineStyle: {color: '#EEEEEE',},},
          },
        ],
        series: [
          {
            type: 'bar', data: this.Part1, barWidth: '0.8333vw',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {offset: 0, color: '#00cfe6',}, // 0% 处的颜色
                      {offset: 1, color: '#17ebd5',}, // 100% 处的颜色
                    ],
                    false
                ),
              },
            },
            label: {
              normal: {
                show: true,
                lineHeight: 30,
                formatter: '{c}',
                position: 'top',
                textStyle: {color: '#00C2AD', fontSize: 16,},
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    statisticListB() {
      let chartDom = document.getElementById('staisticsB')
      let myChart = echarts.init(chartDom)
      let option;
      option = {
        // title: {
        //     text: '散点图',
        //     top: 20, // 定位
        //     left: 20, // 定位
        //     textStyle: {
        //         color: '#28354C',  //标题颜色
        //         // fontSize:'36',
        //     },
        // },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data: ['正常', '偏低', '偏高'],
          axisLabel: {//  改变x轴字体颜色和大小
            textStyle: {
              color: '#62B4BB',
              // fontSize:'20'
            },
          },
        },
        yAxis: {},
        //  legend: {
        //     data: ['正常', '偏低', '偏高'],
        //     right: 40,
        //     top:20,
        //     icon: "circle", // 图例形状
        //     textStyle: {
        //         color: '#62B4BB',   //legend颜色
        //         // fontSize:'20',
        //     },
        // },
        tooltip: {
          trigger: 'item',
        },
        grid: {
          bottom: 30,
          x: 60,
        },
        series: [
          {
            type: 'scatter',
            data: this.Part4,
            color: "#44D4A8", // 线条颜色
          },
        ]
      };
      myChart.setOption(option);
    },
    statisticListC() {
      var chartDom = document.getElementById('staisticsC');
      var myChart = echarts.init(chartDom);
      this.statistResize(chartDom, myChart)
      var category = ['10-20', '20-30', '30-40', '40-50', '50-60', '60-70', '70-80', '80以上'];
      var barData = this.Part2
      var option;
      option = {
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '4%',
          right: '8%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'value',
            axisLine: {show: false},
            axisTick: {show: false},
            splitLine: {lineStyle: {color: '#EEEEEE'}},
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: category,
            axisTick: {show: false,},
            axisLine: {show: false},
            splitLine: {show: false},
            offset: 10,
            nameTextStyle: {
              fontSize: 15
            }
          },
        ],
        series: [
          {
            name: '数量', type: 'bar', data: barData, barGap: 10, smooth: true, barWidth: 16,
            label: {
              normal: {
                show: true, position: 'right',
                offset: [5, -2],
                textStyle: {
                  color: '#47A1Ff',
                  fontSize: 13
                }
              }
            },
            itemStyle: {
              emphasis: {barBorderRadius: 7},
              normal: {
                barBorderRadius: 7,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {offset: 0, color: '#5FACFE',}, // 0% 处的颜色
                      {offset: 1, color: '#A5D0FF',}, // 100% 处的颜色
                    ],
                    false
                ),
              },
            }
          }
        ]
      }
      option && myChart.setOption(option);
    },
    statisticListC1() {
      var chartDom = document.getElementById('staisticsC1');
      var myChart = echarts.init(chartDom);
      this.statistResize(chartDom, myChart)
      var category = ['10-20', '20-30', '30-40', '40-50', '50-60', '60-70', '70-80', '80以上'];
      var barData = this.Part3
      var option;
      option = {
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '4%',
          right: '8%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'value',
            axisLine: {show: false},
            axisTick: {show: false,},
            splitLine: {lineStyle: {color: '#EEEEEE'}},
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: category,
            axisTick: {show: false,},
            axisLine: {show: false},
            splitLine: {show: false},
            offset: 10,
            nameTextStyle: {
              fontSize: 15
            }
          },
        ],
        series: [
          {
            name: '数量', type: 'bar', data: barData, barGap: 10, smooth: true, barWidth: 16,
            label: {
              normal: {
                show: true, position: 'right',
                offset: [5, -2],
                textStyle: {
                  color: '#47A1Ff',
                  fontSize: 13
                }
              }
            },
            itemStyle: {
              emphasis: {barBorderRadius: 7},
              normal: {
                barBorderRadius: 7,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {offset: 0, color: '#5FACFE',}, // 0% 处的颜色
                      {offset: 1, color: '#A5D0FF',}, // 100% 处的颜色
                    ],
                    false
                ),
              },
            }
          }
        ]
      }
      option && myChart.setOption(option);
    },
    statisticListD() {//AI电话接通数
      var chartDom = document.getElementById('staisticsD');
      var myChart = echarts.init(chartDom);
      var option;
      var list = this.Part5
      option = {
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        title: {
          text: this.total,
          subtext: "全年接通数",
          textStyle: {
            fontSize: 30,
            color: "#319EFD",
            lineHeight: 20,
          },
          subtextStyle: {
            fontSize: 12,
            color: "#333",
          },
          textAlign: "center",
          left: "24%",
          top: "40%",
        },
        tooltip: {trigger: 'item'},
        legend: {
          orient: 'left', icon: "circle", top: '35%', left: "61%", y: 'center',
          textStyle: {
            color: "#333333",
            fontSize: 14,
          },
          formatter(name) {
            console.log(name);
            for (var i = 0; i < list.length; i++) {
              if (name == list[i].name) {
                return name + "   " + list[i].value
              }
            }
          },
        },
        color: ["#319efd", "#3becf2", "#ff718e", "#ffdb00"],
        series: [
          {
            name: '随访总数',
            type: 'pie',
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            center: ['25%', '50%'],
            itemStyle: {borderRadius: 10, borderColor: '#fff', borderWidth: 2},
            label: {show: false, position: 'center'},
            // emphasis: {label: {show: true, fontSize: '40', fontWeight: 'bold'}}, labelLine: {show: false},
            data: this.Part5
          }
        ]
      };
      option && myChart.setOption(option);
    },
    // statisticListSF() {
    //   var chartDom = document.getElementById('staisticsSF');
    //   var myChart = echarts.init(chartDom);
    //   let { color, legend } = {
    //     // category: [
    //     //   { name: '随访总数', value: 1, },
    //     //   { name: '本季数', value: 1, },
    //     //   { name: '本月数', value: 1, },
    //     //   { name: '本周', value: 1,},
    //     //   { name: '最近', value: 1,},
    //     // ],
    //     color: [
    //       '#FB6868',
    //       '#FC4040',
    //       '#FF9100',
    //       '#FFC400',
    //       '#2A91FF',
    //       '#67B0FF',
    //       '#45C7C2',
    //       '#58E8D4',
    //       '#754DFF',
    //       '#A4A4FF',
    //     ],
    //     datas: [1, 2, 3, 4, 5],
    //     legend: [
    //       '随访总数',
    //       '本季数',
    //       '本月数',
    //       '本周',
    //       '最近',
    //     ],
    //     time: 2,
    //     total: 100,
    //   };
    //   var dataStyle = {
    //     normal: {
    //       label: {
    //         show: false,
    //       },
    //       labelLine: {
    //         show: false,
    //       },
    //       borderWidth: 10,
    //     },
    //   };
    //   var placeHolderStyle = {
    //     normal: {
    //       color:'transparent',
    //       label: {
    //         show: false,
    //       },
    //       labelLine: {
    //         show: false,
    //       },
    //     },
    //     emphasis: {
    //       color:'transparent',
    //     },
    //   };
    //   var option;
    //   option = {
    //      toolbox: {
    //             feature: {
    //                 saveAsImage: {}
    //             }
    //         },
    //     title: {
    //       text: '',
    //       x: 'center',
    //       y: 'center',
    //     },
    //     tooltip: {
    //       trigger: 'item',
    //       show: true,
    //       formatter: function (params) {
    //         let name = '';
    //         if (params.name.length > 15) {
    //           name += params.name.slice(0, 15) + '<br/>';
    //           name += params.name.slice(15, params.name.length - 1);
    //         } else {
    //           name = params.name;
    //         }
    //         return name + ':<br/>' + params.value + '%';
    //       },
    //       padding: [8, 10], //内边距
    //     },
    //     legend: {
    //       show: true,
    //       orient: 'vertical',
    //       icon: 'circle',
    //       right: 140,
    //       top: 50,
    //       // itemGap: 5,
    //       data: legend,
    //       textStyle: {
    //         rich: {
    //           b: {
    //             fontSize: 14,
    //             padding: [0, 0, 3, 0],
    //           },
    //         },
    //       },
    //       formatter:  (params, i)=> {
    //         let str = '';
    //         this.arrlist.forEach((item) => {
    //           if (item.name === params) {
    //             if (params.length > 3) {
    //               params = params.slice(0, 3) + '...';
    //             }
    //             if (item.name.length == 3) {
    //               str = `{b|${item.name}      ${item.value}}`;
    //             }
    //             if (item.name.length == 2) {
    //               str = `{b|${item.name}         ${item.value}}`;
    //             }
    //             if (item.name.length == 4) {
    //               str = `{b|${item.name}   ${item.value}}`;
    //             }
    //           }
    //         });
    //         return str;
    //       },
    //     },
    //     series: [
    //       {
    //         name: 'Line 3',
    //         type: 'pie',
    //         clockWise: false,
    //         radius: ['60%', '66%'],
    //         center: ['30%', '50%'],
    //         itemStyle: dataStyle,
    //         hoverAnimation: false,
    //         startAngle: 90,
    //         data: [
    //           {
    //             // value: this.arrlist[0].value,
    //             value: 200,
    //             name: this.arrlist[0].name,
    //             itemStyle: {
    //               normal: {
    //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //                   {
    //                     offset: 0,
    //                     color: color[0],
    //                   },
    //                   {
    //                     offset: 1,
    //                     color: color[1],
    //                   },
    //                 ]),
    //               },
    //             },
    //           },
    //           {
    //             // value: total - datas[0],
    //             name: '',
    //             tooltip: {
    //               show: false,
    //             },
    //             itemStyle: placeHolderStyle,
    //           },
    //         ],
    //       },
    //       {
    //         name: 'Line 1',
    //         type: 'pie',
    //         clockWise: false,
    //         radius: ['50%','56%'],
    //         center: ['30%','50%'],
    //         itemStyle: dataStyle,
    //         hoverAnimation: false,
    //         startAngle: 90,
    //         label: {
    //           borderRadius: '10',
    //         },
    //         data: [
    //           {
    //             //  value: this.arrlist[1].value,
    //              value: 60,
    //              name: this.arrlist[1].name,
    //             itemStyle: {
    //               normal: {
    //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //                   {
    //                     offset: 0,
    //                     color: color[2],
    //                   },
    //                   {
    //                     offset: 1,
    //                     color: color[3],
    //                   },
    //                 ]),
    //               },
    //             },
    //           },
    //           {
    //             // value: total - datas[1],
    //             name: '',
    //             tooltip: {
    //               show: false,
    //             },
    //             itemStyle: placeHolderStyle,
    //           },
    //         ],
    //       },
    //       {
    //         name: 'Line 2',
    //         type: 'pie',
    //         clockWise: false,
    //         radius: ['40%', '46%'],
    //         center: ['30%', '50%'],
    //         itemStyle: dataStyle,
    //         hoverAnimation: false,
    //         startAngle: 90,
    //         data: [
    //           {
    //             // value: this.arrlist[2].value,
    //             value: 20,
    //             name: this.arrlist[2].name,
    //             itemStyle: {
    //               normal: {
    //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //                   {
    //                     offset: 0,
    //                     color: color[4],
    //                   },
    //                   {
    //                     offset: 1,
    //                     color: color[5],
    //                   },
    //                 ]),
    //               },
    //             },
    //           },
    //           {
    //             // value: total - datas[2],
    //             name: '',
    //             tooltip: {
    //               show: false,
    //             },
    //             itemStyle: placeHolderStyle,
    //           },
    //         ],
    //       },
    //       {
    //         name: 'Line 3',
    //         type: 'pie',
    //         clockWise: false,
    //         radius: ['30%', '36%'],
    //         center: ['30%', '50%'],
    //         itemStyle: dataStyle,
    //         hoverAnimation: false,
    //         startAngle: 90,
    //         data: [
    //           {
    //             // value: this.arrlist[3].value,
    //             value: 10,
    //             name: this.arrlist[3].name,
    //             itemStyle: {
    //               normal: {
    //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //                   {
    //                     offset: 0,
    //                     color: color[6],
    //                   },
    //                   {
    //                     offset: 1,
    //                     color: color[7],
    //                   },
    //                 ]),
    //               },
    //             },
    //           },
    //           {
    //             // value: total - datas[3],
    //             name: '',
    //             tooltip: {
    //               show: false,
    //             },
    //             itemStyle: placeHolderStyle,
    //           },
    //         ],
    //       },
    //       {
    //         name: 'Line 3',
    //         type: 'pie',
    //         clockWise: false,
    //         radius: ['20%', '26%'],
    //         center: ['30%', '50%'],
    //         itemStyle: dataStyle,
    //         hoverAnimation: false,
    //         startAngle: 90,
    //         data: [
    //           {
    //             // value: this.arrlist[4].value,
    //             value: 4,
    //             name: this.arrlist[4].name,
    //             itemStyle: {
    //               normal: {
    //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //                   {
    //                     offset: 0,
    //                     color: color[8],
    //                   },
    //                   {
    //                     offset: 1,
    //                     color: color[9],
    //                   },
    //                 ]),
    //               },
    //             },
    //           },
    //           {
    //             // value: total - datas[4],
    //             name: '',
    //             tooltip: {
    //               show: false,
    //             },
    //             itemStyle: placeHolderStyle,
    //           },
    //         ],
    //       },
    //     ],
    //   };
    //   option && myChart.setOption(option);
    // },

    statisticListSF() {
      var chartDom = document.getElementById('staisticsSF');
      var myChart = echarts.init(chartDom);
      var option;
      var series = [];
      var pieDatas = this.arrlist;
      var maxRadius = this.devicePixelRatio ? '50' : '80',
          barWidth = 8,
          barGap = 5;
      var arr = [];
      pieDatas.forEach((item, index) => {
        arr.push(item.value);
      });
      var maxValue = Math.max(...arr);
      var showValue = true, showPercent = true;
      var barColor = [
        {
          "color1": "rgb(251, 86, 86)",
          "color2": ""
        },
        {
          "color1": "rgb(255, 165, 0)",
          "color2": ""
        },
        {
          "color1": "rgb(69, 159, 255)",
          "color2": ""
        },
        {
          "color1": "rgb(89, 232, 212)",
          "color2": ""
        },
        {
          "color1": "rgb(133, 107, 255)",
          "color2": ""
        },
      ];
      pieDatas.map((item, i) => {
        series.push({
          type: 'pie',
          clockWise: true, //顺时加载
          hoverAnimation: false, //鼠标移入变大
          // coordinateSystem:'polar',
          radius: [(maxRadius - i * (barGap + barWidth)) + '%', (maxRadius - (i + 1) * barWidth - i * barGap) + '%'],
          center: ["30%", "50%"],
          label: {
            show: false
          },
          itemStyle: {
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            borderWidth: 5,
            borderRadius: 10
          },
          data: [
            {
              value: item.value,
              name: item.name,
              itemStyle: {
                color: barColor[i] && barColor[i].color1 || 'rgba(68,165,255,1)'
              }
            },
            {
              value: maxValue - item.value,
              name: '',
              itemStyle: {
                color: "transparent",
              },
              tooltip: {
                show: false
              },
              hoverAnimation: true
            }]
        })
        series.push({
          name: 'blank',
          type: 'pie',
          silent: true,
          z: 0,
          clockWise: true, //顺时加载
          hoverAnimation: true, //鼠标移入变大
          radius: [(maxRadius - i * (barGap + barWidth)) + '%', (maxRadius - (i + 1) * barWidth - i * barGap) + '%'],
          center: ["50%", "50%"],
          label: {
            show: false
          },
          itemStyle: {
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            borderWidth: 5,
          },
          data: [{
            value: 1,
            itemStyle: {
              color: "rgba(255, 255, 255,.13)",
              borderWidth: 0
            },
            tooltip: {
              show: false
            },
            hoverAnimation: false
          }]
        });
      })

      var option;
      option = {
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        backgroundColor: 'transparent',
        tooltip: {
          show: true,
          trigger: "item",
        },
        legend: {
          show: true,
          left: '60%',
          top: 'middle',
          icon: "circle",
          itemWidth: 15,
          itemHeight: 20,
          itemGap: 10,
          textStyle: {
            fontSize: 16,
            color: '#000',
          },
          formatter: (name) => {
            var datas = pieDatas;
            let total = 0;
            datas.map(item => {
              total += (item.value - 0)
            })
            let valueIndex = datas.map(item => item.name).indexOf(name);
            return name + (name.length == 4 ? '' : name.length == 3 ? '   ' : '      ') + (showValue ? datas[valueIndex].value + (option.legendValueUnit || '') + ' ' : '');
            // + (showValue ? datas[valueIndex].value + (option.legendValueUnit || '') + ' ' : '')
          },
        },
        series: series,
      };

      option && myChart.setOption(option);
    },


    StaisticsMap() { // 患者分布区域
      var chartDom = document.getElementById('StaisticsMap');
      var myChart = echarts.init(chartDom);
      // var outname = ["南海诸岛", '北京', '天津', '上海', '重庆', '河北', '河南', '云南', '辽宁', '黑龙江', '湖南', '安徽', '山东', '新疆', '江苏', '浙江', '江西', '湖北', '广西', '甘肃', '山西', '内蒙古', '陕西', '吉林', '福建', '贵州', '广东', '青海', '西藏', '四川', '宁夏', '海南', '台湾', '香港', '澳门'];
      // var outvalue = [0, 524, 13, 140, 75, 13, 83, 11, 19, 15, 69, 260, 39, 4, 31, 104, 36, 1052, 33, 347, 9, 157, 22, 4, 18, 5, 6398, 41, 0, 484, 404, 22, 3, 5, 225];
      var outdata = [];
      // this.Part6 = [{"p":"广西","m":280000},{"p":"西藏","m":290000},{"p":"上海","m":290000},{"p":"湖北","m":290000},{"p":"江苏","m":290000},{"p":"河南","m":500000},{"p":"新疆","m":0},{"p":"北京","m":0},{"p":"澳门","m":0},{"p":"内蒙古","m":0},{"p":"四川","m":0},{"p":"吉林","m":0},{"p":"甘肃","m":0},{"p":"山东","m":0},{"p":"天津","m":0},{"p":"海南","m":0},{"p":"安徽","m":0},{"p":"江西","m":0},{"p":"辽宁","m":0},{"p":"广东","m":0},{"p":"宁夏","m":0},{"p":"云南","m":0},{"p":"陕西","m":0},{"p":"浙江","m":0},{"p":"福建","m":0},{"p":"青海","m":0},{"p":"河北","m":0},{"p":"黑龙江","m":0},{"p":"重庆","m":0},{"p":"湖南","m":0},{"p":"香港","m":0},{"p":"山西","m":0},{"p":"贵州","m":0},{"p":"台湾","m":0}];
      for (var i = 0; i < this.Part6.length; i++) {
        outdata.push({
          name: this.Part6[i].p,
          value: this.Part6[i].m
        })
      }
      console.log("outdata -->", outdata)
      var option = {
        tooltip: {  //这里设置提示框
          trigger: 'item',  //数据项图形触发
          backgroundColor: "red",  //提示框浮层的背景颜色。
          //字符串模板(地图): {a}（系列名称），{b}（区域名称），{c}（合并数值）,{d}（无）
          formatter: '地区：{b}<br/>人数：{c}'
        },
        visualMap: {//视觉映射组件
          bottom: 10,
          left: 'left',
          min: 0,
          max: 500000,
          text: ['高', '低'],
          realtime: false,  //拖拽时，是否实时更新
          calculable: true,  //是否显示拖拽用的手柄
          inRange: {
            color: ['lightskyblue', 'yellow', 'orangered']
          }
        },
        series: [
          {
            name: '人数',
            type: 'map',
            mapType: 'china',
            roam: true,//是否开启鼠标缩放和平移漫游
            itemStyle: {//地图区域的多边形 图形样式
              normal: {//是图形在默认状态下的样式
                label: {
                  show: true,//是否显示标签
                  textStyle: {
                    color: "black"
                  }
                }
              },
              zoom: 1.5,  //地图缩放比例,默认为1
              emphasis: {//是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
                label: {show: true}
              }
            },
            top: "3%",//组件距离容器的距离
            data: outdata
          }
        ]
      };
      option && myChart.setOption(option);
      // var _self = this
      // myChart.on('click',function (params) {
      //   console.log(params,_self)
      // })
    }
  }
}
</script>

<style scoped lang="scss">
.tolead {
  padding: 1.0417vw;
  box-sizing: border-box;
}

.charts-container {
  height: calc(100vh - 186px);
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.5208vw;

  .title {
    display: flex;
    align-items: center;
    gap: 0.2604vw;

    & > span:first-child {
      background: #3388ff;
      width: 0.2083vw;
      height: 0.7292vw;
      border-radius: 0.5208vw;
    }
  }

  .row1,
  .row2 {
    width: 100%;
    height: 100%;

    & > div {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      box-shadow: 0 0.2604vw 0.5208vw rgba(0, 0, 0, 0.16);
      border-radius: 0.5208vw;
      padding: 0.5208vw;
    }
  }

  .row1 {
    display: grid;
    grid-template-columns: 1fr 0.8fr 0.8fr;
    gap: 0.5208vw;

    .chart1 {
      .content {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 0.5208vw;
        padding: 0.2604vw 0.5208vw;

        & > div:first-child {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 0.5208vw;

          & > div {
            box-sizing: border-box;
            padding: 0.2604vw 0.5208vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          & > div:first-child {
            background: linear-gradient(to right, #00CFE6 0%, #17EBD5 100%);
            border-radius: 0.5208vw;
            color: #FFF;

            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;

              & > p {
                font-size: 1.7708vw;
              }
            }
          }

          & > div:nth-of-type(2) {
            background: linear-gradient(to right, #FF9A00 0%, #FFE800 100%);
            border-radius: 0.5208vw;
            color: #FFF;

            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;

              & > p {
                font-size: 1.7708vw;
              }
            }
          }

          & > div:last-child {
            background: linear-gradient(to right, #59CAFF 0%, #2F94FF 100%);
            border-radius: 0.5208vw;
            color: #FFF;

            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;

              & > p {
                font-size: 1.7708vw;
              }
            }
          }
        }

        & > div:last-child {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 1.0417vw;

          & > div {
            box-sizing: border-box;
            padding: 0.2604vw 0.5208vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          & > div:first-child {
            background: linear-gradient(to right, #FF8EB3 0%, #FF589B 100%);
            border-radius: 0.5208vw;
            color: #FFF;

            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;

              & > p {
                font-size: 1.7708vw;
              }
            }
          }

          & > div:nth-of-type(2) {
            background: linear-gradient(to right, #7E5CFF 0%, #9B93FF 100%);
            border-radius: 0.5208vw;
            color: #FFF;

            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;

              & > p {
                font-size: 1.7708vw;
              }
            }
          }

          & > div:nth-of-type(3) {
            background: linear-gradient(to right, #FFB45B 0%, #FF9F13 100%);
            border-radius: 0.5208vw;
            color: #FFF;

            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;

              & > p {
                font-size: 1.7708vw;
              }
            }
          }

          & > div:nth-of-type(4) {
            background: linear-gradient(to right, #A582F9 0%, #CB69FD 100%);
            border-radius: 0.5208vw;
            color: #FFF;

            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;

              & > p {
                font-size: 1.7708vw;
              }
            }
          }
        }
      }
    }

    .chart2 {
      display: flex;
      flex-direction: column;

      .follow-number {
        width: 2.0833vw;
        height: 2.0833vw;
        font-size: 1.5625vw;
        text-align: center;
        color: #ffffff;
        background: linear-gradient(180deg, #496CF3 0%, #819DFE 100%);
        border-radius: 0.3125vw 0.3125vw 0.3125vw 0.3125vw;
        margin-right: 0.625vw;
      }

      .bottomdiv {
        background-image: url('../../assets/image/taskmanagement/dataRepotrBg.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: 0vw;
        background-position-x: 80%;
        margin-left: 0.5208vw;
        margin-top: 0.5208vw;
        height: 100%;
        color: #666666;
      }
    }

    .chart3 {
    }
  }

  .row2 {
    display: grid;
    grid-template-columns: 0.7fr 0.7fr 1fr;
    grid-template-rows: 1fr 0.8fr;
    gap: 0.5208vw;

    .chart4 {
    }

    .chart5 {
    }

    .chart6 {
      grid-row-end: span 2;
    }
  }
}
</style>
